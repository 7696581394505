import { Col, Layout, Row } from 'antd'
import { Link } from 'gatsby'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export default () => {
  const linkStyle = { color: 'inherit' }

  const footerItems = [
    {
      name: 'terms-of-use',
      md: 6,
      lg: 4,
    },
    {
      name: 'privacy-policy',
      md: 6,
      lg: 5,
    },
    {
      name: 'cookie-statement',
      md: 8,
      lg: 6,
    },
    {
      name: 'contact',
      md: 6,
      lg: 4,
    },
    {
      name: 'campaign-laws-rules',
      md: 6,
      lg: 4,
    },
  ]

  return (
    <Layout.Footer
      style={{
        backgroundColor: '#1890ff',
        color: 'black',
      }}
    >
      <div className="container">
        <Row className="h4 center">
          <Col md={18}>
            <Row>
              {footerItems.map((item) => (
                <Col xs={24} md={item.md} lg={item.lg} key={item.name} className="center">
                  <Link to={`/${item.name}/`} style={linkStyle}>
                    <FormattedMessage id={`comp.layout.footer.${item.name}`} />
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={24} md={6}>
            <Row>
              <Col xs={24} className="center">
                &copy; 2021 Raffskie
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout.Footer>
  )
}
