import { BackTop, Layout as AntLayout } from 'antd'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

import I18nProvider from '../I18nProvider'

import './style.css'
import Cookie from './Cookie'
import Footer from './Footer'
import Header from './Header'

import Background from './bg.svg'

const contentStyle = {
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  marginTop: '64px',
}

const Layout = ({ children, selectedMenuKey, customStyle }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            description
            keywords
            siteUrl
            title
          }
        }
      }
    `}
    render={(data) => {
      const {
        description,
        keywords,
        siteUrl,
        title,
      } = data.site.siteMetadata

      return (
        <>
          <Helmet
            meta={[
              { name: 'description', content: description },
              { name: 'keywords', content: keywords },

              { property: 'og:locale', content: 'en_US' },
              { property: 'og:type', content: 'website' },
              { property: 'og:title', content: title },
              { property: 'og:description', content: description },
              { property: 'og:url', content: siteUrl },
              { property: 'og:site_name', content: 'Raffskie' },

              { property: 'twitter:card', content: 'summary' },
              { property: 'twitter:description', content: description },
              { property: 'twitter:title', content: title },
            ]}
            title={title}
          >
            <html lang="en" />
          </Helmet>
          <I18nProvider>
            <AntLayout
              className="flex flex-column"
              style={{
                backgroundColor: 'white',
                minHeight: '100vh',
              }}
            >
              <Header selectedMenuKey={selectedMenuKey} />
              <AntLayout.Content style={customStyle || contentStyle}>
                {children}
              </AntLayout.Content>
              <Footer />
              <BackTop />
              <Cookie />
            </AntLayout>
          </I18nProvider>
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  selectedMenuKey: PropTypes.string,
  customStyle: PropTypes.object,
}

Layout.defaultProps = {
  selectedMenuKey: undefined,
  customStyle: undefined,
}

export default Layout
