import Cache from '@aws-amplify/cache'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import {
  Button,
  Drawer,
  Layout,
  Menu,
  notification,
} from 'antd'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import { capitalize } from 'lodash'

import navNew from '../../assets/img/nav_new.png'
import { industries, aboutMenu } from '../../constants/raffle'

import LogoLink from './LogoLink'

const Header = ({ selectedMenuKey }) => {
  const { SubMenu } = Menu
  const [drawerVisible, setDrawerVisible] = useState(false)

  const navNewStyle = {
    width: '30px',
    marginLeft: '3px',
    marginBottom: '20px',
  }

  const submenuStyle = {
    backgroundColor: '#f0f2f5',
    marginBottom: '0',
    marginTop: '0',
    textAlign: 'center',
  }

  const popBackerCampaign = () => {
    notification.open({
      key: 'BackerCampaignNotification',
      message: (
        <span>
          Backer Campaigns
          <img src={navNew} alt="new" style={navNewStyle} />
        </span>
      ),
      description: (
        <div>
          <p className="bold">What are Backer Campaigns?</p>
          <p>
            Backer Campaigns are designed to create interest for a specific kind of Contest or Giveaway.
            They never go Live until the Campaign meets the minimum interest and goal. After which time every
            Backer automatically becomes an Entrant.
            A Backer Campaign on Raffskie is all-or-nothing. No one will be entered or charged for a pledge
            towards a Backer Campaign unless it reaches the minimum requirement to go Live.
            {' '}
          </p>

          <p className="bold">What is a Backer?</p>
          <p>
            A Backer is someone who pledges support for a specific kind of Contest or Giveaway in the hopes it
            will have created the minimum interest and requirement to go Live.
          </p>
        </div>
      ),
    })
  }

  const renderDesktopMenu = () => (
    <div className="container xs-hide sm-hide px3">
      <LogoLink />
      <div className="right">
        <Menu
          mode="horizontal"
          onClick={() => setDrawerVisible(false)}
          selectedKeys={[selectedMenuKey]}
          style={{
            border: 'none',
            lineHeight: '62px',
            marginLeft: '1rem',
          }}
        >
          <SubMenu key="campaigns" title="Campaigns" className="h4">
            {industries.map((industry) => (
              <Menu.Item key={industry} style={submenuStyle} className="h4">
                <Link
                  state={{ industry }}
                  to={`/${industry}/`}
                  onClick={() => Cache.setItem('rsk-web.industry', industry)}
                >
                  {capitalize(industry)}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
          <SubMenu key="about" title="About" className="h4">
            {aboutMenu.map((menu) => (
              <Menu.Item key={menu} style={submenuStyle} className="h4">
                <Link to={`/${menu}`}>
                  <FormattedMessage id={`comp.layout.${menu}`} />
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
          <Menu.Item key="backer-campaigns" onClick={popBackerCampaign} className="h4">
            <FormattedMessage id="backer-campaigns" />
            <img src={navNew} alt="new" style={navNewStyle} />
          </Menu.Item>
          <Menu.Item key="cant-find" className="h4">
            <Link to="/cant-find">
              <FormattedMessage id="comp.layout.cant-find-page" />
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )

  const renderMobileMenu = () => (
    <>
      <div className="md-hide lg-hide">
        <LogoLink />
        <span className="right">
          <Button
            icon={drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onClick={() => setDrawerVisible(!drawerVisible)}
          />
        </span>
        <Drawer
          bodyStyle={{ padding: 0 }}
          onClose={() => setDrawerVisible(!drawerVisible)}
          placement="left"
          title={<LogoLink showPreview={false} />}
          visible={drawerVisible}
        >
          <Menu
            onClick={() => setDrawerVisible(false)}
            selectedKeys={[selectedMenuKey]}
            mode="inline"
            defaultOpenKeys={['campaigns', 'about']}
          >
            <SubMenu key="campaigns" title="Campaigns" className="h4">
              {industries.map((industry) => (
                <Menu.Item key={industry} className="h4">
                  <Link
                    state={{ industry }}
                    to={`/${industry}/`}
                    onClick={() => Cache.setItem('rsk-web.industry', industry)}
                  >
                    { capitalize(industry) }
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
            <SubMenu key="about" title="About" className="h4">
              {aboutMenu.map((menu) => (
                <Menu.Item key={menu} className="h4">
                  <Link to={`/${menu}`}>
                    <FormattedMessage id={`comp.layout.${menu}`} />
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
            <Menu.Item key="backer-campaigns-mobile" onClick={popBackerCampaign} className="h4">
              <FormattedMessage id="backer-campaigns" />
              <img src={navNew} alt="new" style={navNewStyle} />
            </Menu.Item>
            <Menu.Item key="cant-find" className="h4">
              <Link to="/cant-find">
                <FormattedMessage id="comp.layout.cant-find-page" />
              </Link>
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    </>
  )

  return (
    <Layout.Header
      className="fixed"
      style={{
        backgroundColor: 'white',
        boxShadow: '0 1px 4px rgba(0,21,41,.08)',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        width: '100%',
        zIndex: 3,
      }}
    >
      {renderDesktopMenu()}
      {renderMobileMenu()}
    </Layout.Header>
  )
}

Header.propTypes = {
  selectedMenuKey: PropTypes.string,
}

Header.defaultProps = {
  selectedMenuKey: undefined,
}

export default Header
