export const industries = [
  'health',
  'leisure',
]

export const aboutMenu = [
  'howitworks',
  'sponsor',
  'affiliate',
  'faqs',
  'contact',
]

export const countries = {
  Canada: 'CA',
  'United States': 'US',
}

export const states = {
  Canada: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Northwest Territories',
    'Nunavut',
    'Yukon',
  ],
  'United States': [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
}

export const errorCodes = {
  expired_card: { error: 'Expired Card', message: 'Please Use a different card' },
  card_declined: { error: 'Card Declined', message: 'Due to' },
  incorrect_cvc: { error: 'Incorrect CVC', message: 'Please Use a different card' },
  incorrect_zip: { error: 'Incorrect ZIP', message: "The card's ZIP is incorrect" },
  incorrect_number: { error: 'Incorred Card Number', message: 'Please Use a different card' },
  invalid_cvc: { error: 'Invalid CVC', message: 'Please Use a different card' },

}

export const categoriesJson = {
  health: {
    Pharmaceutical: [
      'Anticoagulants',
      'Antifungal',
      'Antiplatelets',
      'Blood Pressure',
      'Cholesterol',
      'COPD and Asthma',
      'Crohn\'s disease and Ulcerative colitis',
      'Diabetes',
      'Erectile Dysfunction',
      'Glaucoma',
      'Hypothyroidism',
      'Menopause',
      'Nocturnal leg cramps',
      'Osteoarthritis and Rheumatoid Arthritis',
      'Overactive bladder',
    ],
  },
  leisure: {
    Golf: [],
  },
}
