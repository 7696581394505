import { Tag } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import React from 'react'

import amplify from '../../assets/conf/amplify.json'

import Logo from './logo.svg'

const LogoLink = ({ showPreview }) => (
  <>
    <Link to="/">
      <img alt="Raffskie" className="mr1" src={Logo} width={160} />
    </Link>
    {showPreview && amplify.stage !== 'prod' && (
      <Tag className="bold caps ml1" color="gold">
        PREVIEW
      </Tag>
    )}
  </>
)

LogoLink.propTypes = {
  showPreview: PropTypes.bool,
}

LogoLink.defaultProps = {
  showPreview: true,
}

export default LogoLink
