import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const LOCAL_STORAGE_COOKIE = 'rsk-web.cookie'

export default () => {
  const [accepted, setAccepted] = useState(true)

  useEffect(() => {
    const isAccepted = window.localStorage.getItem(LOCAL_STORAGE_COOKIE) === 'true'
    setAccepted(isAccepted)
  }, [])

  const handleAccepted = () => {
    setAccepted(true)
    window.localStorage.setItem(LOCAL_STORAGE_COOKIE, true)
  }

  return accepted ? (
    null
  ) : (
    <div
      className="fixed"
      style={{
        backgroundColor: '#fffbe6',
        bottom: 0,
        width: '100%',
        zIndex: 10,
      }}
    >
      <div className="container my2">
        <FormattedMessage id="comp.layout.cookie.description" />
        <Button
          className="ml2"
          onClick={handleAccepted}
          size="small"
          type="primary"
        >
          <FormattedMessage id="comp.layout.cookie.accept" />
        </Button>
      </div>
    </div>
  )
}
